"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function ArticleUnTierDeuxTiersImage({
  as: _Component = _Builtin.Layout,
  firstImage = "https://uploads-ssl.webflow.com/65e730dac2c13b1de20db419/65e9907faaf023dae3011919_article-img-2.jpeg",
  secondImage = "https://uploads-ssl.webflow.com/65e730dac2c13b1de20db419/65e9908304bdefcc6fb60340_artilce-img-1.jpeg",
}) {
  return (
    <_Component
      className="article-grid-image"
      id="w-node-_3415d123-ae01-0bad-9ba4-54ae7a813839-7a813839"
    >
      <_Builtin.Cell id="w-node-_3415d123-ae01-0bad-9ba4-54ae7a81383a-7a813839">
        <_Builtin.Image
          className="image-8"
          loading="lazy"
          width="auto"
          height="auto"
          alt=""
          src={firstImage}
        />
      </_Builtin.Cell>
      <_Builtin.Cell
        className="cell"
        id="w-node-_3415d123-ae01-0bad-9ba4-54ae7a81383c-7a813839"
      >
        <_Builtin.Image
          className="image-9"
          loading="lazy"
          width="auto"
          height="auto"
          alt=""
          src={secondImage}
        />
      </_Builtin.Cell>
    </_Component>
  );
}
