import { SectionArticleRecommended as SectionArticleRecommendedDevlink } from "devlink";
import { articleTypes } from "entities/article";
import { CardArticle } from "widgets/card-article";

type SectionArticleRecommendedProps = {
  article: articleTypes.Article;
};
export function SectionArticleRecommended({
  article,
}: SectionArticleRecommendedProps) {
  if (!article.recommandations || article.recommandations.length < 1)
    return null;
  return (
    <SectionArticleRecommendedDevlink
      slotArticlesRecommended={article.recommandations.map((article) => (
        <CardArticle key={article.id} article={article} />
      ))}
    />
  );
}
