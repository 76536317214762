import { useLayoutEffect, useRef } from "react";
import * as stratis from "stratis-ui-icons";

type IconComponentProps = {
  name: string;
  size?: number;
  color?: string;
};
export function IconComponent({
  name,
  size = 16,
  color = undefined,
}: IconComponentProps) {
  const { completeIconSet, ...icons } = stratis;
  const icon = icons[name as keyof typeof icons] || icons["suLightbulb01"];
  const myRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (myRef.current) {
      myRef.current.innerHTML = icon.data.replace(
        /fill="[^"]*"/,
        'fill="#FFFFFF00"',
      );
    }
  }, [myRef, icon.data]);

  return (
    <div
      ref={myRef}
      style={{
        display: "flex",
        alignContent: "center",
        width: `${size}px`,
        height: `${size}px`,
        ...(color && { color: color }),
      }}
    />
  );
}
