"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import * as _interactions from "./interactions";

const _interactionsData = JSON.parse(
  '{"events":{"e-65":{"id":"e-65","name":"","animationType":"preset","eventTypeId":"SCROLL_INTO_VIEW","action":{"id":"","actionTypeId":"FADE_EFFECT","instant":false,"config":{"actionListId":"fadeIn","autoStopEventId":"e-66"}},"mediaQueries":["main","medium","small","tiny"],"target":{"id":"12c162f6-e811-9c18-03db-f5523a6bb2a9","appliesTo":"ELEMENT","styleBlockIds":[]},"targets":[{"id":"12c162f6-e811-9c18-03db-f5523a6bb2a9","appliesTo":"ELEMENT","styleBlockIds":[]}],"config":{"loop":false,"playInReverse":false,"scrollOffsetValue":15,"scrollOffsetUnit":"%","delay":3,"direction":null,"effectIn":true},"createdOn":1710950889393},"e-67":{"id":"e-67","name":"","animationType":"preset","eventTypeId":"SCROLL_INTO_VIEW","action":{"id":"","actionTypeId":"FADE_EFFECT","instant":false,"config":{"actionListId":"fadeIn","autoStopEventId":"e-68"}},"mediaQueries":["main","medium","small","tiny"],"target":{"id":"12c162f6-e811-9c18-03db-f5523a6bb2aa","appliesTo":"ELEMENT","styleBlockIds":[]},"targets":[{"id":"12c162f6-e811-9c18-03db-f5523a6bb2aa","appliesTo":"ELEMENT","styleBlockIds":[]}],"config":{"loop":false,"playInReverse":false,"scrollOffsetValue":15,"scrollOffsetUnit":"%","delay":0,"direction":null,"effectIn":true},"createdOn":1710950943695}},"actionLists":{"fadeIn":{"id":"fadeIn","useFirstGroupAsInitialState":true,"actionItemGroups":[{"actionItems":[{"actionTypeId":"STYLE_OPACITY","config":{"delay":0,"duration":0,"target":{"id":"N/A","appliesTo":"TRIGGER_ELEMENT","useEventTarget":true},"value":0}}]},{"actionItems":[{"actionTypeId":"STYLE_OPACITY","config":{"delay":0,"easing":"outQuart","duration":1000,"target":{"id":"N/A","appliesTo":"TRIGGER_ELEMENT","useEventTarget":true},"value":1}}]}]}},"site":{"mediaQueries":[{"key":"main","min":992,"max":10000},{"key":"medium","min":768,"max":991},{"key":"small","min":480,"max":767},{"key":"tiny","min":0,"max":479}]}}'
);

export function ArticleCitation({
  as: _Component = _Builtin.Block,
  title = "Nous gérons en interne tous les aspects liés à la distribution des lentilles, cela inclut l’approvisionnement, le stockage, la préparation des commandes, et le service client.",
  author = "Jérôme Duchemin, Directeur de la logistique",
}) {
  _interactions.useInteractions(_interactionsData);

  return (
    <_Component
      className="content-article-interne-testimonials padding-global padding-large"
      tag="div"
    >
      <_Builtin.Block className="padding-quotes-article-testimonials" tag="div">
        <_Builtin.HtmlEmbed
          className="html-embed-8"
          data-w-id="12c162f6-e811-9c18-03db-f5523a6bb2a9"
          value="%3Csvg%20width%3D%2228%22%20height%3D%2238%22%20viewBox%3D%220%200%2028%2038%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M3.28933%2028.8066C11.3218%2021.4839%2018.8902%2012.0642%2024.1801%202.55278C24.6414%201.72196%2024.3409%200.67337%2023.5091%200.214987C22.6772%20-0.243395%2021.627%200.0545793%2021.1657%200.885398C16.0505%2010.0817%208.73091%2019.1921%200.963809%2026.2684C0.261783%2026.9101%200.212951%2027.9987%200.854656%2028.6978C1.49636%2029.3968%202.5873%2029.4483%203.28933%2028.8066Z%22%20fill%3D%22%239EBFFF%22%2F%3E%0A%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M5.32616%2036.8044C12.6727%2038.0133%2020.2451%2035.9506%2026.9252%2033.0342C27.7967%2032.6503%2028.1943%2031.6362%2027.8134%2030.771C27.432%2029.9%2026.4145%2029.5046%2025.543%2029.8828C19.4592%2032.5414%2012.5785%2034.5125%205.88743%2033.4124C4.94871%2033.2577%204.06113%2033.8937%203.90659%2034.8276C3.75148%2035.7616%204.38802%2036.6497%205.32616%2036.8044Z%22%20fill%3D%22%239EBFFF%22%2F%3E%0A%3C%2Fsvg%3E"
        />
        <_Builtin.HtmlEmbed
          className="html-embed-7"
          data-w-id="12c162f6-e811-9c18-03db-f5523a6bb2aa"
          value="%3Csvg%20width%3D%2228%22%20height%3D%2238%22%20viewBox%3D%220%200%2028%2038%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M3.28933%2028.8066C11.3218%2021.4839%2018.8902%2012.0642%2024.1801%202.55278C24.6414%201.72196%2024.3409%200.67337%2023.5091%200.214987C22.6772%20-0.243395%2021.627%200.0545793%2021.1657%200.885398C16.0505%2010.0817%208.73091%2019.1921%200.963809%2026.2684C0.261783%2026.9101%200.212951%2027.9987%200.854656%2028.6978C1.49636%2029.3968%202.5873%2029.4483%203.28933%2028.8066Z%22%20fill%3D%22%239EBFFF%22%2F%3E%0A%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M5.32616%2036.8044C12.6727%2038.0133%2020.2451%2035.9506%2026.9252%2033.0342C27.7967%2032.6503%2028.1943%2031.6362%2027.8134%2030.771C27.432%2029.9%2026.4145%2029.5046%2025.543%2029.8828C19.4592%2032.5414%2012.5785%2034.5125%205.88743%2033.4124C4.94871%2033.2577%204.06113%2033.8937%203.90659%2034.8276C3.75148%2035.7616%204.38802%2036.6497%205.32616%2036.8044Z%22%20fill%3D%22%239EBFFF%22%2F%3E%0A%3C%2Fsvg%3E"
        />
      </_Builtin.Block>
      <_Builtin.Block
        className="wrapper-article-interne-testimonials"
        tag="div"
      >
        <_Builtin.Heading className="heading-style-h4" tag="h4">
          {title}
        </_Builtin.Heading>
        <_Builtin.Paragraph className="text-p2-med text-color-secondary">
          {author}
        </_Builtin.Paragraph>
      </_Builtin.Block>
    </_Component>
  );
}
