import { Link } from "react-router-dom";
import { pathKeys } from "shared/lib/react-router";
import styles from "./page-404.module.css";
import { articleFilterStore } from "pages/home/home-page.model";

export function Page404() {
  return (
    <div className={styles["outer-wrapper"]}>
      <div className={styles["inner-wrapper"]}>
        <div className="container">
          <h1 className="logo-font">Page non trouvée</h1>
          <p>La page que vous avez demandé n'existe pas.</p>
          <Link
            to={pathKeys.home()}
            className={`btn btn-sm btn-outline-primary ${styles["light-blue-link"]}`}
            onClick={() => {
              articleFilterStore.getState().reset();
            }}
          >
            Retour à l'accueil
          </Link>
        </div>
      </div>
    </div>
  );
}
