import { TagArticle as TagArticleDevlink } from "devlink";
import { tagTypes } from "entities/tag";
import { StoreApi } from "zustand";
import { articleModel } from "entities/article";
import { useLayoutEffect, useState } from "react";
import { pathKeys } from "shared/lib/react-router";

type TagArticleProps = {
  tag: tagTypes.Tag;
  filterStore?: StoreApi<articleModel.FilterState>;
};
export function TagArticle({ tag, filterStore }: TagArticleProps) {
  const [isActive, setIsActive] = useState(isTagActive());

  useLayoutEffect(() => {
    filterStore?.subscribe((state: articleModel.State) => {
      setIsActive(isTagActive());
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStore]);

  function isTagActive() {
    return filterStore?.getState().filterQuery.tags?.includes(tag.id);
  }

  function handleTagClick(tag: tagTypes.Tag) {
    const tags = filterStore?.getState().filterQuery.tags || [];

    if (tags.length > 0 && tags.includes(tag.id)) {
      tags?.splice(0, tags.length);
    } else {
      tags?.splice(0, tags.length);
      tags?.push(tag.id);
    }

    filterStore?.setState({
      ...filterStore?.getState(),
      filterQuery: { ...filterStore?.getState().filterQuery, tags },
      pageQuery: { ...filterStore?.getState().pageQuery, page: 1 },
    });

    window.location.href = pathKeys.home();
  }

  return (
    <TagArticleDevlink
      name={`#${tag.nom}`}
      rpTagArticle={{
        className: `text-p1-bold hashtag ${isActive && "is-active"}`,
        onClick: (e: React.MouseEvent) => {
          e.preventDefault();
          handleTagClick(tag);
        },
      }}
    />
  );
}
