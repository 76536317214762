import { RouterProvider, createBrowserRouter, redirect, useRouteError } from "react-router-dom";
import { GenericLayout } from "pages/layouts";
import { homePageRoute } from "pages/home";
import { page404Route } from "pages/page-404";
import { pathKeys } from "shared/lib/react-router";
import { articlePageRoute } from "pages/article";

function BubbleError() {
  const error = useRouteError();
  if (error) throw error;
  return null;
}

const router = createBrowserRouter([
  {
    errorElement: <BubbleError />,
    children: [
      {
        element: <GenericLayout />,
        children: [homePageRoute, articlePageRoute, page404Route],
      },
      {
        loader: async () => redirect(pathKeys.home()),
        path: "/",
      },
      {
        loader: async () => redirect(pathKeys.page404()),
        path: "*",
      },
    ],
  },
]);

export function BrowserRouter() {
  return <RouterProvider router={router} />;
}
