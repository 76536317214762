import {
  keepPreviousData,
  queryOptions as tsqQueryOptions,
} from "@tanstack/react-query";
import { queryClient } from "shared/lib/react-query";
import {
  Article,
  Articles,
  FilterQueryDto,
  PageQueryDto,
} from "./article.types";
import { articleQuery, articlesQuery } from "./article.api";
import { StoreApi } from "zustand";
import { articleModel } from ".";

const keys = {
  root: () => ["article"],
  article: (slug: string) => [...keys.root(), "bySlug", slug],
  articles: (page: number) => [...keys.root(), "articles", page],
  articlesByFilter: (page: number, filter: FilterQueryDto) => [
    ...keys.articles(page),
    "byFilter",
    filter,
  ],
  infinityQuery: () => [...keys.root(), "infinityQuery"],
  infinityQueryByFilter: (filter: FilterQueryDto) => [
    ...keys.infinityQuery(),
    "byFilter",
    filter,
  ],
};

export const articleService = {
  queryKey: (slug: string) => keys.article(slug),

  getCache: (slug: string) =>
    queryClient.getQueryData<Article>(articleService.queryKey(slug)),

  setCache: (article: Article) =>
    queryClient.setQueryData(articleService.queryKey(article.slug), article),

  removeCache: (slug: string) =>
    queryClient.removeQueries({ queryKey: articleService.queryKey(slug) }),

  queryOptions: (slug: string) => {
    const articleKey = articleService.queryKey(slug);
    return tsqQueryOptions({
      queryKey: articleKey,
      queryFn: async ({ signal }) => {
        const article = await articleQuery({ slug }, signal);
        articleService.setCache(article);

        return article;
      },
      initialData: () => articleService.getCache(slug)!,
      initialDataUpdatedAt: () =>
        queryClient.getQueryState(articleKey)?.dataUpdatedAt,
    });
  },

  prefetchQuery: async (slug: string) =>
    queryClient.prefetchQuery(articleService.queryOptions(slug)),

  ensureQueryData: async (slug: string) =>
    queryClient.ensureQueryData(articleService.queryOptions(slug)),
};

export const paginatedArticlesService = {
  queryKey: (page: number, filterQuery: FilterQueryDto) =>
    keys.articlesByFilter(page, filterQuery),

  getCache: (page: number, filterQuery: FilterQueryDto) =>
    queryClient.getQueryData<Articles>(
      paginatedArticlesService.queryKey(page, filterQuery),
    ),

  paginatedQueryOptions: (filterStore: StoreApi<articleModel.FilterState>) => {
    return tsqQueryOptions({
      queryKey: paginatedArticlesService.queryKey(
        filterStore.getState().pageQuery.page,
        filterStore.getState().filterQuery,
      ),
      queryFn: async ({ signal }) => {
        const articles = await articlesQuery(
          {
            query: {
              ...filterStore.getState().pageQuery,
              ...filterStore.getState().filterQuery,
            },
          },
          signal,
        );

        filterStore.setState(
          (state) => ({ ...state, pageQuery: articles.meta }),
          false,
        );

        return articles;
      },
      placeholderData: keepPreviousData,
    });
  },

  queryOptions: (pageQuery: PageQueryDto, filterQuery: FilterQueryDto) => {
    return tsqQueryOptions({
      queryKey: paginatedArticlesService.queryKey(pageQuery.page, filterQuery),
      queryFn: async ({ signal }) => {
        const articles = await articlesQuery(
          {
            query: {
              ...pageQuery,
              ...filterQuery,
            },
          },
          signal,
        );

        return articles;
      },
    });
  },

  prefetchQuery: async (filterStore: StoreApi<articleModel.FilterState>) =>
    queryClient.prefetchQuery(
      paginatedArticlesService.paginatedQueryOptions(filterStore),
    ),

  cancelQuery: (filterStore: StoreApi<articleModel.FilterState>) =>
    queryClient.cancelQueries({
      queryKey: paginatedArticlesService.queryKey(
        filterStore.getState().pageQuery.page,
        filterStore.getState().filterQuery,
      ),
    }),
};
