import { BlogMainWrapper } from "devlink";
import { SectionHero } from "widgets/section-hero";
import { articleFilterStore } from "./home-page.model";
import { SectionTopArticles } from "widgets/section-top-articles";
import { SectionListArticles } from "widgets/section-list-articles";

export function HomePage() {
  return (
    <BlogMainWrapper
      slotBlogMainWrapper={
        <>
          <SectionHero filterStore={articleFilterStore} />
          <SectionTopArticles filterStore={articleFilterStore} />
          <SectionListArticles filterStore={articleFilterStore} />
        </>
      }
    />
  );
}
