"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import { SectionHero } from "./SectionHero";
import { SectionTopArticles } from "./SectionTopArticles";
import { SectionListArticles } from "./SectionListArticles";
import { SectionResultatRecherche } from "./SectionResultatRecherche";

export function BlogMainWrapper({
  as: _Component = _Builtin.Block,
  slotBlogMainWrapper,
}) {
  return (
    <_Component className="main-wrapper" tag="main">
      {slotBlogMainWrapper ?? (
        <>
          <SectionHero />
          <SectionTopArticles />
          <SectionListArticles />
          <SectionResultatRecherche />
        </>
      )}
    </_Component>
  );
}
