import { articleSeoTypes } from "entities/article-seo";

export const renderFacebookMetaTags = (
  seo: articleSeoTypes.Seo,
  currentSocial: articleSeoTypes.SeoMetaSocial,
): JSX.Element[] => [
  <meta
    key="og:title"
    property="og:title"
    content={currentSocial.title || undefined}
  />,
  <meta
    key="og:description"
    property="og:description"
    content={currentSocial.description || undefined}
  />,
  <meta key="og:image" property="og:image" content={seo.metaImage?.url} />,
  <meta
    key="og:image:width"
    property="og:image:width"
    content={seo.metaImage?.width?.toString()}
  />,
  <meta
    key="og:image:height"
    property="og:image:height"
    content={seo.metaImage?.height?.toString()}
  />,
  <meta
    key="og:url"
    property="og:url"
    content={seo.canonicalURL || undefined}
  />,
  <meta key="og:type" property="og:type" content="article" />,
  <meta key="og:locale" property="og:locale" content="fr_FR" />,
];
