import { ArticleAuthor as ArticleAuthorDevlink } from "devlink";
import { authorTypes } from "entities/author";
import { imageUrl } from "shared/api/sensee";
import "./article-author.css";

type ArticleAuthorProps = {
  author: authorTypes.Author;
};
export function ArticleAuthor({ author }: ArticleAuthorProps) {
  return (
    <ArticleAuthorDevlink
      image={
        author.image?.formats?.thumbnail
          ? imageUrl(author.image.formats.thumbnail.url)
          : author.image?.url
            ? imageUrl(author.image.url)
            : "/images/default-image.jpg"
      }
      fullname={author.nom}
      poste={author.poste}
      rpShareButton={{
        onClick: (event: any) => {
          event.preventDefault();
          const textShareButton =
            event.currentTarget.querySelector(".text-share-button");
          if (textShareButton) {
            const svg = event.currentTarget.querySelector(".flex-center");
            svg.style.display = "none";
            textShareButton.textContent = "Le lien a bien été copié !";
            setTimeout(() => {
              textShareButton.textContent = "Partager le lien de l'article";
              svg.style.display = "flex";
            }, 2000);
          }
          navigator.clipboard.writeText(window.location.href);
        },
      }}
    />
  );
}
