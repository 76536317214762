"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function ArticleQuatreImages({
  as: _Component = _Builtin.Block,
  firstImage = "https://uploads-ssl.webflow.com/65e730dac2c13b1de20db419/65e9908304bdefcc6fb60340_artilce-img-1.jpeg",
  secondImage = "https://uploads-ssl.webflow.com/65e730dac2c13b1de20db419/65e86d07d1b001ab755b73a0_top-article-3.png",
  thirdImage = "https://uploads-ssl.webflow.com/65e730dac2c13b1de20db419/65e9907d42793497b69c253d_article-img-3.jpeg",
  fourthImage = "https://uploads-ssl.webflow.com/65e730dac2c13b1de20db419/65e9907faaf023dae3011919_article-img-2.jpeg",
}) {
  return (
    <_Component className="row-images-article" tag="div">
      <_Builtin.Image
        className="image-row-footer-article"
        loading="lazy"
        width="auto"
        height="auto"
        alt=""
        src={firstImage}
      />
      <_Builtin.Image
        className="image-row-footer-article"
        loading="lazy"
        width="auto"
        height="auto"
        alt=""
        src={secondImage}
      />
      <_Builtin.Image
        className="image-row-footer-article"
        loading="lazy"
        width="auto"
        height="auto"
        alt=""
        src={thirdImage}
      />
      <_Builtin.Image
        className="image-row-footer-article"
        loading="lazy"
        width="auto"
        height="auto"
        alt=""
        src={fourthImage}
      />
    </_Component>
  );
}
