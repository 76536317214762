"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function PageNumber({
  as: _Component = _Builtin.Paragraph,
  rpPageNumber = {},
  text = "1",
}) {
  return (
    <_Component
      className="text-p1-med pagination-link is-active"
      {...rpPageNumber}
    >
      {text}
    </_Component>
  );
}
