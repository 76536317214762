import { StateCreator } from "zustand";
import { FilterQueryDto, PageQueryDto } from "./article.types";

export type State = {
  pageQuery: PageQueryDto;
  filterQuery: FilterQueryDto;
};

export type Actions = {
  nextPage: () => void;
  previousPage: () => void;
  changeFilter: (filterQuery: FilterQueryDto) => void;
  reset: () => void;
};

export type FilterState = State & Actions;
export const createArticleFilterSlice =
  (initialState: State): StateCreator<FilterState, []> =>
  (set) => ({
    ...initialState,
    nextPage: () =>
      set(
        (state) => ({
          pageQuery: { ...state.pageQuery, page: state.pageQuery.page + 1 },
        }),
        false,
      ),
    previousPage: () =>
      set(
        (state) => ({
          pageQuery: { ...state.pageQuery, page: state.pageQuery.page - 1 },
        }),
        false,
      ),
    changeFilter: (filterQuery: FilterQueryDto) => set({ filterQuery }, false),
    reset: () => set(initialState, false),
  });
