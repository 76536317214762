"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function ArticleRichText({
  as: _Component = _Builtin.Block,
  slotMardown,
}) {
  return (
    <_Component className="text-content-article" tag="div">
      <_Builtin.RichText tag="div">{slotMardown}</_Builtin.RichText>
    </_Component>
  );
}
