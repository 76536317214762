import {
  ArticleImageFull,
  ArticleRichText,
  ArticleTextPhotoDroite,
  ArticleTextPhotoGauche,
  ArticleDeuxTiersImage,
  ArticleUnTierDeuxTiersImage,
  ArticleTroisImages,
  ArticleQuatreImages,
  ArticleCitation,
  ArticleImageHalfHalf,
  ArticleCardCta,
} from "devlink";
import { articleCorpsTypes } from "entities/article-corps";
import { imageUrl } from "shared/api/sensee";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";

/**
 * Rich text component
 */

type RichTextProps = {
  corps: articleCorpsTypes.RichTextDto;
};
export function RichText({ corps }: RichTextProps) {
  return (
    <ArticleRichText
      slotMardown={
        <ReactMarkdown
          remarkPlugins={[gfm]}
          children={corps.contenu.replace(/\n\n/gi, "&nbsp;\n")}
        />
      }
    />
  );
}

/**
 * Photo full component
 */

type PhotoFullProps = {
  corps: articleCorpsTypes.PhotoFullDto;
};
export function PhotoFull({ corps }: PhotoFullProps) {
  return (
    <ArticleImageFull
      image={
        corps.image.data?.attributes.url
          ? imageUrl(corps.image.data?.attributes.url)
          : "/images/default-image.jpg"
      }
    />
  );
}

/**
 * Text right photo component
 */

type TextRightPhotoProps = {
  corps: articleCorpsTypes.TextRightPhotoDto;
};
export function TextRightPhoto({ corps }: TextRightPhotoProps) {
  return (
    <ArticleTextPhotoDroite
      content={<ReactMarkdown>{corps.contenu}</ReactMarkdown>}
      image={
        corps.image.data?.attributes.url
          ? imageUrl(corps.image.data?.attributes.url)
          : "/images/default-image.jpg"
      }
    />
  );
}

/**
 * Text left photo component
 */

type TextLeftPhotoProps = {
  corps: articleCorpsTypes.TextLeftPhotoDto;
};
export function TextLeftPhoto({ corps }: TextLeftPhotoProps) {
  return (
    <ArticleTextPhotoGauche
      image={
        corps.image.data?.attributes.url
          ? imageUrl(corps.image.data?.attributes.url)
          : "/images/default-image.jpg"
      }
      content={<ReactMarkdown>{corps.contenu}</ReactMarkdown>}
    />
  );
}

/**
 * Image one third two third component
 */

type ImageOneThirdTwoThirdImageProps = {
  corps: articleCorpsTypes.PhotoOneThirdTwoThirdDto;
};
export function ImageOneThirdTwoThird({
  corps,
}: ImageOneThirdTwoThirdImageProps) {
  return (
    <ArticleDeuxTiersImage
      firstImage={
        corps.imageUnTier.data?.attributes.url
          ? imageUrl(corps.imageUnTier.data?.attributes.url)
          : "/images/default-image.jpg"
      }
      secondImage={
        corps.imageDeuxTiers.data?.attributes.url
          ? imageUrl(corps.imageDeuxTiers.data?.attributes.url)
          : "/images/default-image.jpg"
      }
    />
  );
}

/**
 * Image two third one third component
 */

export function ImageTwoThirdOneThird({
  corps,
}: ImageOneThirdTwoThirdImageProps) {
  return (
    <ArticleUnTierDeuxTiersImage
      firstImage={
        corps.imageUnTier.data?.attributes.url
          ? imageUrl(corps.imageUnTier.data?.attributes.url)
          : "/images/default-image.jpg"
      }
      secondImage={
        corps.imageDeuxTiers.data?.attributes.url
          ? imageUrl(corps.imageDeuxTiers.data?.attributes.url)
          : "/images/default-image.jpg"
      }
    />
  );
}

/**
 * Image half half component
 */

type ImageHalfHalfProps = {
  corps: articleCorpsTypes.ImageHalfHalfDto;
};
export function ImageHalfHalf({ corps }: ImageHalfHalfProps) {
  return (
    <ArticleImageHalfHalf
      firstImage={
        corps.imageGauche.data?.attributes.url
          ? imageUrl(corps.imageGauche.data?.attributes.url)
          : "/images/default-image.jpg"
      }
      secondImage={
        corps.imageDroite.data?.attributes.url
          ? imageUrl(corps.imageDroite.data?.attributes.url)
          : "/images/default-image.jpg"
      }
    />
  );
}

/**
 * Trois images component
 */

type TroisImagesProps = {
  corps: articleCorpsTypes.ThirdImagesDto;
};
export function TroisImages({ corps }: TroisImagesProps) {
  return (
    <ArticleTroisImages
      firstImage={
        corps.image1.data?.attributes.url
          ? imageUrl(corps.image1.data?.attributes.url)
          : "/images/default-image.jpg"
      }
      secondImage={
        corps.image2.data?.attributes.url
          ? imageUrl(corps.image2.data?.attributes.url)
          : "/images/default-image.jpg"
      }
      thirdImage={
        corps.image3.data?.attributes.url
          ? imageUrl(corps.image3.data?.attributes.url)
          : "/images/default-image.jpg"
      }
    />
  );
}

/**
 * Quatre images component
 */

type QuatreImagesProps = {
  corps: articleCorpsTypes.FourImagesDto;
};
export function QuatreImages({ corps }: QuatreImagesProps) {
  return (
    <ArticleQuatreImages
      firstImage={
        corps.image1.data?.attributes.url
          ? imageUrl(corps.image1.data?.attributes.url)
          : "/images/default-image.jpg"
      }
      secondImage={
        corps.image2.data?.attributes.url
          ? imageUrl(corps.image2.data?.attributes.url)
          : "/images/default-image.jpg"
      }
      thirdImage={
        corps.image3.data?.attributes.url
          ? imageUrl(corps.image3.data?.attributes.url)
          : "/images/default-image.jpg"
      }
      fourthImage={
        corps.image4.data?.attributes.url
          ? imageUrl(corps.image4.data?.attributes.url)
          : "/images/default-image.jpg"
      }
    />
  );
}

/**
 * Citation component
 */

type CitationProps = {
  corps: articleCorpsTypes.CitationDto;
};
export function Citation({ corps }: CitationProps) {
  return (
    <ArticleCitation
      title={corps.contenu}
      author={`${corps.auteur.data.attributes.nom}, ${corps.auteur.data.attributes.poste}`}
    />
  );
}

/**
 * Cta component
 */
type CtaProps = {
  corps: articleCorpsTypes.ArticleCardCtaDto;
};

export function CardCta({ corps }: CtaProps) {
  return (
    <ArticleCardCta
      title={corps.title}
      content={corps.content}
      contentButton={corps.titleButton}
      link={{ href: corps.lien, target: "_blank" }}
    />
  );
}
