import { zodContract } from "shared/lib/zod";
import { createJsonQuery } from "shared/lib/fetch";
import { baseUrl } from "shared/api/sensee";
import { CategoriesDtoSchema } from "./category.contracts";
import { Categories } from "./category.types";
import { mapCategories } from "./category.lib";

export async function categoriesQuery(
  signal?: AbortSignal,
): Promise<Categories> {
  return createJsonQuery({
    request: {
      url: baseUrl("/categorie-articles"),
      method: "GET",
    },
    response: {
      contract: zodContract(CategoriesDtoSchema),
      mapData: mapCategories,
    },
    abort: signal,
  });
}
