import { ChipCategory as ChipCategoryDevlink } from "devlink";
import { articleModel } from "entities/article";
import { categoryTypes } from "entities/category";
import { useLayoutEffect, useState } from "react";
import { IconComponent } from "shared/ui/icon";
import { StoreApi } from "zustand";

type ChipCategoryProps = {
  category: categoryTypes.Category;
  filterStore: StoreApi<articleModel.FilterState>;
};
export function ChipCategory({ category, filterStore }: ChipCategoryProps) {
  const [isActive, setIsActive] = useState(isChipActive());

  useLayoutEffect(() => {
    filterStore.subscribe((state: articleModel.State) => {
      setIsActive(isChipActive());
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStore]);

  function isChipActive() {
    return filterStore.getState().filterQuery.categories?.includes(category.id);
  }

  function handleChipCategoryClick(categoriy: categoryTypes.Category) {
    const categories = filterStore.getState().filterQuery.categories || [];
    const index = categories?.indexOf(categoriy.id);

    // remove the category if it is in the filters
    if (index !== -1) {
      categories?.splice(index!, 1);
    }
    // else add the category to the filters
    else {
      categories.length = 0;
      categories?.push(categoriy.id);
    }

    filterStore
      .getState()
      .changeFilter({ ...filterStore.getState().filterQuery, categories });
    filterStore.getState().pageQuery = {
      ...filterStore.getState().pageQuery,
      page: 1,
    };
  }

  return (
    <ChipCategoryDevlink
      name={category.nom}
      slotPictogramme={<IconComponent name={category.pictogramme} />}
      rpChipCategory={{
        className: `chip-categorie ${isActive && "is-chip-active"}`,
        onClick: (e: React.MouseEvent) => {
          e.preventDefault();
          handleChipCategoryClick(category);
        },
      }}
    />
  );
}
