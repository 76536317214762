import { useQuery } from "@tanstack/react-query";
import {
  SectionListArticles as SectionListArticlesDevlink,
  SectionResultatRecherche,
  CardArticleSkeleton,
  ErrorCard,
} from "devlink";
import { articleModel, articleQueries } from "entities/article";
import { categoryQueries } from "entities/category";
import { tagQueries } from "entities/tag";
import { useLayoutEffect, useState } from "react";
import { BlogPagination } from "widgets/blog-pagination";
import { CardArticle } from "widgets/card-article";
import { StoreApi } from "zustand";
import "./section-list-article.css";
import { ChipCategory } from "widgets/chip-categorie";
import { Article, Articles } from "entities/article/article.types";

type SectionListArticlesProps = {
  filterStore: StoreApi<articleModel.FilterState>;
};

/**
 * Fonction qui retourne les articles mappés en CardArticle
 * @param articles Tab d'articles à mapper
 * @returns
 */
export function mapArticlesToCardArticles(
  articles: Articles,
  filterStore?: StoreApi<articleModel.FilterState>,
) {
  return articles.data.map((article: Article) => (
    <CardArticle key={article.id} article={article} filterStore={filterStore} />
  ));
}

/**
 * Fonction qui retourne un skeleton de chargement
 * @returns CardArticleSkeleton qui est un skeleton de chargement
 */
export function loadingSkeleton() {
  return (
    <>
      {Array.from({ length: 12 }).map((_, index) => (
        <CardArticleSkeleton key={index} />
      ))}
    </>
  );
}

export function SectionListArticles({ filterStore }: SectionListArticlesProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pageQuery, setPageQuery] = useState(filterStore.getState().pageQuery);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filterQuery, setFilterQuery] = useState(
    filterStore.getState().filterQuery,
  );

  const { data: categories } = useQuery(
    categoryQueries.categoriesService.queryOptions(),
  );
  const {
    data: articles,
    isLoading,
    error,
  } = useQuery(
    articleQueries.paginatedArticlesService.paginatedQueryOptions(filterStore),
  );
  const { data: tags } = useQuery(tagQueries.tagsService.queryOptions({}));

  useLayoutEffect(() => {
    filterStore.subscribe((state: articleModel.State) => {
      setPageQuery(state.pageQuery);
      setFilterQuery(state.filterQuery);
    });
  }, [filterStore]);

  useLayoutEffect(() => {
    if (articles?.meta) {
      setPageQuery({ ...pageQuery, total: articles.meta.total });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articles]);

  return (
    <>
      {filterStore.getState().filterQuery.tags &&
        filterStore.getState().filterQuery.tags?.length !== 0 && (
          <SectionResultatRecherche
            rpResetFilter={{
              onClick: () => {
                filterStore.getState().reset();
              },
            }}
            slotTag={
              tags
                ? `Affichage des résultats pour "${tags
                    .filter((tag) =>
                      filterStore.getState().filterQuery.tags?.includes(tag.id),
                    )
                    .map((tag) => tag.nom)
                    .join(", ")}"`
                : ""
            }
            slotNombreArticle={`Nous avons trouvé ${pageQuery.total} résultats correspondant à votre recherche.`}
            slotArticles={
              isLoading
                ? loadingSkeleton()
                : articles
                  ? mapArticlesToCardArticles(articles, filterStore)
                  : null
            }
            slotPagination={<BlogPagination filterStore={filterStore} />}
          />
        )}
      {(!filterStore.getState().filterQuery.tags ||
        filterStore.getState().filterQuery.tags?.length === 0) &&
        !error && (
          <SectionListArticlesDevlink
            slotChipCategories={
              categories ? (
                categories.map((category) => (
                  <ChipCategory
                    key={category.id}
                    category={category}
                    filterStore={filterStore}
                  />
                ))
              ) : (
                <p className="text-color-secondary">Aucune catégorie</p>
              )
            }
            slotListArticles={
              isLoading
                ? loadingSkeleton()
                : articles
                  ? mapArticlesToCardArticles(articles, filterStore)
                  : null
            }
            slotPagination={<BlogPagination filterStore={filterStore} />}
          />
        )}
      {error && (
        <div style={{ width: "80%", margin: "auto" }}>
          <ErrorCard content={error.message} />
        </div>
      )}
    </>
  );
}
