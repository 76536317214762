"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import { ArticleLinkAriane } from "./ArticleLinkAriane";
import { ArianeSeparator } from "./ArianeSeparator";

export function FilAriane({ as: _Component = _Builtin.Block, slotContent }) {
  return (
    <_Component className="container-fil-ariane" tag="div">
      {slotContent ?? (
        <>
          <ArticleLinkAriane />
          <ArianeSeparator />
          <ArticleLinkAriane />
        </>
      )}
    </_Component>
  );
}
