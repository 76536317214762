import {
  ArianeSeparator,
  ArticleLinkAriane,
  FilAriane as FilArianeDevLink,
  SectionArticleHero as SectionArticleHeroDevLink,
} from "devlink";
import { imageUrl } from "shared/api/sensee";
import { Article } from "entities/article/article.types";
import { TagArticle } from "widgets/tag-article";
import { pathKeys } from "shared/lib/react-router";
import "./section-article-hero.css";
import { CategoryArticle } from "widgets/category-article";
import { StoreApi } from "zustand";
import { articleModel } from "entities/article";

type SectionArticleHeroProps = {
  article: Article;
  filterStore: StoreApi<articleModel.FilterState>;
};
export function SectionArticleHero({
  article,
  filterStore,
}: SectionArticleHeroProps) {
  return (
    <SectionArticleHeroDevLink
      slotFilAriane={<FilAriane article={article} />}
      title={article.titre}
      slotCategoryArticle={article.categoriesArticle?.map((category) => (
        <CategoryArticle key={category.id} category={category} />
      ))}
      slotTagsArticle={article.tags?.map((tag) => (
        <TagArticle filterStore={filterStore} key={tag.id} tag={tag} />
      ))}
      rpBackgroundImage={{
        style: {
          backgroundImage: `url(${article.image?.url ? imageUrl(article.image?.url) : "/images/default-image.jpg"})`,
        },
      }}
    />
  );
}

function FilAriane({ article }: { article: Article }) {
  return (
    <FilArianeDevLink
      slotContent={
        <>
          <ArticleLinkAriane content="Blog" link={{ href: pathKeys.home() }} />
          <ArianeSeparator />
          <ArticleLinkAriane
            content={article.titre}
            link={{ href: pathKeys.article.bySlug({ slug: article.slug }) }}
          />
        </>
      }
    />
  );
}
