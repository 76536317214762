"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function ArticleDeuxTiersImage({
  as: _Component = _Builtin.Layout,
  firstImage = "https://uploads-ssl.webflow.com/65e730dac2c13b1de20db419/65e9908304bdefcc6fb60340_artilce-img-1.jpeg",
  secondImage = "https://uploads-ssl.webflow.com/65e730dac2c13b1de20db419/65e9907faaf023dae3011919_article-img-2.jpeg",
}) {
  return (
    <_Component
      className="article-grid-image"
      id="w-node-ffe71c47-052f-b89f-4fd2-54430c398667-0c398667"
    >
      <_Builtin.Cell id="w-node-ffe71c47-052f-b89f-4fd2-54430c398668-0c398667">
        <_Builtin.Image
          className="image-9"
          loading="lazy"
          width="auto"
          height="auto"
          alt=""
          src={firstImage}
        />
      </_Builtin.Cell>
      <_Builtin.Cell
        className="cell"
        id="w-node-ffe71c47-052f-b89f-4fd2-54430c39866a-0c398667"
      >
        <_Builtin.Image
          className="image-8"
          loading="lazy"
          width="auto"
          height="auto"
          alt=""
          src={secondImage}
        />
      </_Builtin.Cell>
    </_Component>
  );
}
