import { createElement } from "react";
import { RouteObject } from "react-router-dom";
import { pathKeys } from "shared/lib/react-router";
import { HomePage } from "./home-page.ui";
// import { tagQueries } from "entities/tag";
// import { articleQueries } from "entities/article";
// import { articleFilterStore } from "./home-page.model";

export const homePageRoute: RouteObject = {
  path: pathKeys.home(),
  element: createElement(HomePage),
  loader: async (args) => {
    Promise.all([
      // tagQueries.tagsService.prefetchQuery(),
      // articleQueries.infinityArticlesService.prefetchQuery(articleFilterStore),
    ]);
    return args;
  },
};
