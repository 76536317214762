"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function ArticleImageFull({
  as: _Component = _Builtin.Image,
  image = "https://uploads-ssl.webflow.com/65e730dac2c13b1de20db419/65e99371e4afc92bd7b16c98_image-article-5.jpeg",
}) {
  return (
    <_Component
      className="image-6"
      loading="lazy"
      width="auto"
      height="auto"
      alt=""
      src={image}
    />
  );
}
