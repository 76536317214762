"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function ArticleLinkAriane({
  as: _Component = _Builtin.Link,
  content = "Ariane",

  link = {
    href: "#",
  },
}) {
  return (
    <_Component
      className="link-fil-ariane"
      button={false}
      block="inline"
      options={link}
    >
      <_Builtin.Paragraph>{content}</_Builtin.Paragraph>
    </_Component>
  );
}
