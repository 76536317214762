import { articleTypes } from "entities/article";
import { Helmet } from "react-helmet";
import { renderFacebookMetaTags } from "./article-facebook-meta.ui";
import { renderTwitterMetaTags } from "./article-twitter-meta.ui";

type ArticleSeoProps = {
  article: articleTypes.Article;
};
export function ArticleSeo({ article }: ArticleSeoProps) {
  console.log(article);

  if (!article.seo) return null;

  const metaTags: JSX.Element[] = [];

  if (article.seo.metaSocial) {
    article.seo.metaSocial.forEach((social) => {
      if (social.socialNetwork === "Facebook" && article.seo) {
        metaTags.push(...renderFacebookMetaTags(article.seo, social));
      } else if (social.socialNetwork === "Twitter" && article.seo) {
        metaTags.push(...renderTwitterMetaTags(article.seo, social));
      }
    });
  }

  return (
    <Helmet>
      <title>{article.titre}</title>
      <meta
        name="description"
        content={article.seo.metaDescription || undefined}
      />

      {metaTags}
      <meta
        property="article:published_time"
        content={new Date(article.createdAt).toISOString()}
      />
      <meta
        property="article:modified_time"
        content={new Date(article.publishedAt).toISOString()}
      />
      <meta property="article:author" content={article.auteur?.nom} />
      <meta
        property="article:tag"
        content={article.tags?.map((t) => t.nom).join(", ")}
      />
      <link rel="canonical" href={article.seo.canonicalURL || undefined} />
    </Helmet>
  );
}
