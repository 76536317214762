"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function ArticleTextPhotoGauche({
  as: _Component = _Builtin.Block,
  image = "https://uploads-ssl.webflow.com/65e730dac2c13b1de20db419/65e99371e4afc92bd7b16c98_image-article-5.jpeg",
  content = "Lorem ipsum dolor sit amet consectetur. Purus risus nulla tempor fermentum amet. Odio nunc pretium eget at blandit. Cras facilisis sapien dui felis turpis turpis fringilla eu pellentesque. Posuere morbi adipiscing odio purus aliquet hendrerit tincidunt turpis.",
}) {
  return (
    <_Component className="content-article-right-text-photo" tag="div">
      <_Builtin.Image
        className="image-rounded"
        loading="lazy"
        width="auto"
        height="auto"
        alt=""
        src={image}
      />
      <_Builtin.Paragraph className="text-p1-med content-text">
        {content}
      </_Builtin.Paragraph>
    </_Component>
  );
}
