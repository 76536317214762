import { SlugPageParams } from "./react-router.types";

export const pathKeys = {
  root: "/blog-lmc/",
  home() {
    return pathKeys.root;
  },
  article: {
    bySlug({ slug }: SlugPageParams) {
      return pathKeys.root.concat(slug);
    },
  },
  page404() {
    return pathKeys.root.concat("404/");
  },
};
