import { mapImage } from "entities/image/image.lib";
import { LeadMagnet, LeadMagnetDto } from "./lead-magnet.types";

export function mapLeadMagnet(
  leadMagnet: LeadMagnetDto,
): LeadMagnet | undefined {
  if (!leadMagnet.data) {
    return undefined;
  }
  return {
    id: leadMagnet.data.id,
    ...leadMagnet.data.attributes,
    image: leadMagnet.data.attributes.image
      ? mapImage(leadMagnet.data.attributes.image)
      : undefined,
  };
}
