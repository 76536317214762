import { articleModel } from "entities/article";
import { createStore } from "zustand";
import { DevtoolsOptions, devtools, persist } from "zustand/middleware";

const initialArticleFilterState: articleModel.State = {
  pageQuery: { page: 1, pageSize: 12 },
  filterQuery: {
    topArticle: false,
    populate: "image,vignette,tags,categoriesArticle",
  },
};
const articleFilterStoreDevtoolsOptions: DevtoolsOptions = {
  name: "HomePage ArticleFilterStore",
};

// TODO : make the store shared
export const articleFilterStore = createStore<articleModel.FilterState>()(
  persist(
    devtools(
      articleModel.createArticleFilterSlice(initialArticleFilterState),
      articleFilterStoreDevtoolsOptions,
    ),
    {
      name: "article-filter-store",
    },
  ),
);
