import { ImageDtoSchema, ImageSchema } from "entities/image/image.contracts";
import { z } from "zod";

export const SeoMetaSocialSchema = z.object({
  id: z.number(),
  socialNetwork: z.string(),
  title: z.string().nullable(),
  description: z.string().nullable(),
  metaImage: ImageDtoSchema.optional().nullable(),
});

export const SeoMetaSocialDtoSchema = z.object({
  id: z.number(),
  socialNetwork: z.string(),
  title: z.string().nullable(),
  description: z.string().nullable(),
  metaImage: ImageDtoSchema.optional().nullable(),
});

export const SeoDtoSchema = z.object({
  id: z.number(),
  metaTitle: z.string().nullable(),
  metaDescription: z.string().nullable(),
  metaRobots: z.string().nullable(),
  structuredData: z.string().nullable(),
  metaViewport: z.string().nullable(),
  canonicalURL: z.string().nullable(),
  keywords: z.string().nullable(),
  metaImage: ImageDtoSchema.optional().nullable(),
  metaSocial: z.array(SeoMetaSocialDtoSchema).optional(),
});

export const SeoSchema = z.object({
  id: z.number(),
  metaTitle: z.string().nullable(),
  metaDescription: z.string().nullable(),
  metaRobots: z.string().nullable(),
  structuredData: z.string().nullable(),
  metaViewport: z.string().nullable(),
  canonicalURL: z.string().nullable(),
  keywords: z.string().nullable(),
  metaImage: ImageSchema.optional().nullable(),
  metaSocial: z.array(SeoMetaSocialDtoSchema).optional(),
});
