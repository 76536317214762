import { zodContract } from "shared/lib/zod";
import { createJsonQuery } from "shared/lib/fetch";
import { baseUrl } from "shared/api/sensee";
import { ArticleDtoSchema, ArticlesDtoSchema } from "./article.contracts";
import { ArticlesQueryDto } from "./article.types";
import { mapArticle, mapArticleQuery, mapArticles } from "./article.lib";

export async function articleQuery(
  params: { slug: string },
  signal?: AbortSignal,
) {
  return createJsonQuery({
    request: {
      url: baseUrl(`/articles/${params.slug}`),
      method: "GET",
      query: {
        "populate[corps][populate]": "*",
        "populate[auteur][populate]": "*",
        "populate[leadMagnet][populate]": "*",
        "populate[seo][populate]": "*",
        "populate[recommandations][populate]":
          "image,vignette,tags,categoriesArticle",
        "populate[image]": "*",
        "populate[tags]": "*",
        "populate[categoriesArticle]": "*",
      },
    },
    response: {
      contract: zodContract(ArticleDtoSchema),
      mapData: mapArticle,
    },
    abort: signal,
  });
}

export async function articlesQuery(
  params: { query: ArticlesQueryDto },
  signal?: AbortSignal,
) {
  return createJsonQuery({
    request: {
      url: baseUrl("/articles"),
      method: "GET",
      query: mapArticleQuery(params.query),
    },
    response: {
      contract: zodContract(ArticlesDtoSchema),
      mapData: mapArticles,
    },
    abort: signal,
  });
}
