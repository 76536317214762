import { zodContract } from "shared/lib/zod";
import { createJsonQuery } from "shared/lib/fetch";
import { baseUrl } from "shared/api/sensee";
import { TagsDtoSchema } from "./tag.contracts";
import { Tags, TagsFilterQuery } from "./tag.types";
import { mapTags, mapTagsQuery } from "./tag.lib";

export async function tagsQuery(
  params: { query: TagsFilterQuery },
  signal?: AbortSignal,
): Promise<Tags> {
  return createJsonQuery({
    request: {
      url: baseUrl("/tags"),
      method: "GET",
      query: mapTagsQuery(params.query),
    },
    response: {
      contract: zodContract(TagsDtoSchema),
      mapData: mapTags,
    },
    abort: signal,
  });
}
