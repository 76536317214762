import { createElement } from "react";
import { RouteObject } from "react-router-dom";
import { articleQueries } from "entities/article";
import { invalidDataError } from "shared/lib/fetch";
import { pathKeys, routerContracts } from "shared/lib/react-router";
import { zodContract } from "shared/lib/zod";
import { ArticlePage } from "./article-page.ui";

export const articlePageRoute: RouteObject = {
  path: pathKeys.root,
  children: [
    {
      path: ":slug",
      element: createElement(ArticlePage),
      loader: async (args) => {
        const contract = zodContract(routerContracts.SlugPageParamsSchema);

        if (!contract.isData(args.params)) {
          throw invalidDataError({
            validationErrors: contract.getErrorMessages(args.params),
            response: {},
          });
        }

        Promise.all([
          articleQueries.articleService.prefetchQuery(args.params.slug),
        ]);

        return args;
      },
    },
  ],
};
