"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function ErrorCard({
  as: _Component = _Builtin.Block,
  content = "Could not fetch data",
}) {
  return (
    <_Component className="content-error" tag="div">
      <_Builtin.Paragraph className="error-icon flex-center">
        {"!"}
      </_Builtin.Paragraph>
      <_Builtin.Paragraph>{content}</_Builtin.Paragraph>
    </_Component>
  );
}
