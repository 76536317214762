"use client";

console.warn(
  "⚠️ Avoid importing components from 'index.js' for better performance. This practice is deprecated and may be removed in the future."
);

export * as _Builtin from "./_Builtin";
export * from "./devlinkContext";
export * from "./interactions";
export * from "./utils";
export * from "./devlink";
export * from "./ArianeSeparator";
export * from "./ArticleAuthor";
export * from "./ArticleCardCta";
export * from "./ArticleChapeau";
export * from "./ArticleCitation";
export * from "./ArticleCta";
export * from "./ArticleDeuxTiersImage";
export * from "./ArticleImageFull";
export * from "./ArticleImageHalfHalf";
export * from "./ArticleLinkAriane";
export * from "./ArticleMainWrapper";
export * from "./ArticleQuatreImages";
export * from "./ArticleRichText";
export * from "./ArticleTextPhotoDroite";
export * from "./ArticleTextPhotoGauche";
export * from "./ArticleTopInfos";
export * from "./ArticleTroisImages";
export * from "./ArticleUnTierDeuxTiersImage";
export * from "./BlogMainWrapper";
export * from "./CardArticle";
export * from "./CardArticleSkeleton";
export * from "./CategorieArticle";
export * from "./ChipCategory";
export * from "./CtaBig";
export * from "./CtaSmall";
export * from "./DevLinkProvider";
export * from "./ErrorCard";
export * from "./FilAriane";
export * from "./GlobalStyles";
export * from "./Navbar";
export * from "./PageEllipsis";
export * from "./PageNumber";
export * from "./RowCtaSmall";
export * from "./SectionArticleContent";
export * from "./SectionArticleHero";
export * from "./SectionArticleRecommended";
export * from "./SectionCta";
export * from "./SectionHero";
export * from "./SectionListArticles";
export * from "./SectionResultatRecherche";
export * from "./SectionTopArticles";
export * from "./TagArticle";
