import { queryOptions as tsqQueryOptions } from "@tanstack/react-query";
import { queryClient } from "shared/lib/react-query";
import { Categories } from "./category.types";
import { categoriesQuery } from "./category.api";

const keys = {
  root: () => ["category"],
  categories: () => [...keys.root(), "categories"] as const,
};

export const categoriesService = {
  queryKey: () => keys.categories(),

  getCache: () =>
    queryClient.getQueryData<Categories>(categoriesService.queryKey()),

  setCache: (categories: Categories) =>
    queryClient.setQueryData(categoriesService.queryKey(), categories),

  removeCache: () =>
    queryClient.removeQueries({ queryKey: categoriesService.queryKey() }),

  queryOptions: () => {
    const categoriesKey = categoriesService.queryKey();
    return tsqQueryOptions({
      queryKey: categoriesKey,
      queryFn: async ({ signal }) => categoriesQuery(signal),
      initialData: () => categoriesService.getCache()!,
      initialDataUpdatedAt: () =>
        queryClient.getQueryState(categoriesKey)?.dataUpdatedAt,
    });
  },

  prefetchQuery: async () => {
    queryClient.prefetchQuery(categoriesService.queryOptions());
  },

  ensureQueryData: async () => {
    queryClient.ensureQueryData(categoriesService.queryOptions());
  },
};
