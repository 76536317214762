import {
  Categories,
  CategoriesDto,
  Category,
  CategoryDto,
} from "./category.types";

export function mapCategory(categoryDto: CategoryDto): Category {
  return { id: categoryDto.data.id, ...categoryDto.data.attributes };
}

export function mapCategories(categoriesDto: CategoriesDto): Categories {
  return categoriesDto.data.map((categoryDto) => ({
    id: categoryDto.id,
    ...categoryDto.attributes,
  }));
}
