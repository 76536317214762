"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function TagArticle({
  as: _Component = _Builtin.Paragraph,
  name = "#Tag1",
  rpTagArticle = {},
}) {
  return (
    <_Component className="text-p1-bold hashtag" {...rpTagArticle}>
      {name}
    </_Component>
  );
}
