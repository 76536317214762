import dayjs from "dayjs";
import { CardArticle as CardArticleDevlink } from "devlink";
import { articleModel, articleTypes } from "entities/article";
import { imageUrl } from "shared/api/sensee";
import { TagArticle } from "widgets/tag-article";
import "./card-article.css";
import { CategoryArticle } from "widgets/category-article";
import { pathKeys } from "shared/lib/react-router";
import { StoreApi } from "zustand";

type CardArticleProps = {
  article: articleTypes.Article;
  filterStore?: StoreApi<articleModel.FilterState>;
  className?: string;
};
export function CardArticle({
  article,
  filterStore,
  className = "",
}: CardArticleProps) {
  return (
    <CardArticleDevlink
      rpCardArticle={{
        className: `card-article ${className}`,
      }}
      link={{ href: `${pathKeys.article.bySlug({ slug: article.slug })}` }}
      image={
        article.vignette
          ? imageUrl(article.vignette.url)
          : article.image
            ? imageUrl(article.image.url)
            : "/images/default-image.jpg"
      }
      title={article.titre}
      chapeau={article.chapeauApercu || article.chapeau}
      date={dayjs(article.updatedAt).format("DD/MM/YYYY")}
      slotArticleTags={article.tags?.map((tag) => (
        <TagArticle key={tag.id} tag={tag} filterStore={filterStore} />
      ))}
      slotArticleCategories={article.categoriesArticle?.map((category) => (
        <CategoryArticle key={category.id} category={category} />
      ))}
    />
  );
}
