import { queryOptions as tsqQueryOptions } from "@tanstack/react-query";
import { queryClient } from "shared/lib/react-query";
import { Tags, TagsFilterQuery } from "./tag.types";
import { tagsQuery } from "./tag.api";

const keys = {
  root: () => ["tag"],
  tags: () => [...keys.root(), "tags"] as const,
};

export const tagsService = {
  queryKey: () => keys.tags(),

  getCache: () => queryClient.getQueryData<Tags>(tagsService.queryKey()),

  setCache: (tags: Tags) =>
    queryClient.setQueryData(tagsService.queryKey(), tags),

  removeCache: () =>
    queryClient.removeQueries({ queryKey: tagsService.queryKey() }),

  queryOptions: (query: TagsFilterQuery) => {
    const tagsKey = tagsService.queryKey();
    return tsqQueryOptions({
      queryKey: tagsKey,
      queryFn: async ({ signal }) => tagsQuery({ query }, signal),
      initialData: () => tagsService.getCache()!,
      initialDataUpdatedAt: () =>
        queryClient.getQueryState(tagsKey)?.dataUpdatedAt,
    });
  },

  prefetchQuery: async (query: TagsFilterQuery) => {
    queryClient.prefetchQuery(tagsService.queryOptions(query));
  },

  ensureQueryData: async (query: TagsFilterQuery) => {
    queryClient.ensureQueryData(tagsService.queryOptions(query));
  },
};
