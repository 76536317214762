import { mapImage } from "entities/image/image.lib";
import { Author, AuthorDto } from "./author.types";

export function mapAuthor(author: AuthorDto): Author | undefined {
  if (!author.data) {
    return undefined;
  }
  return {
    id: author.data.id,
    ...author.data.attributes,
    image: author.data.attributes.image
      ? mapImage(author.data.attributes.image)
      : undefined,
  };
}
