"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function ArticleTroisImages({
  as: _Component = _Builtin.Layout,
  firstImage = "https://uploads-ssl.webflow.com/65e730dac2c13b1de20db419/65e9907d42793497b69c253d_article-img-3.jpeg",
  secondImage = "https://uploads-ssl.webflow.com/65e730dac2c13b1de20db419/65e9907faaf023dae3011919_article-img-2.jpeg",
  thirdImage = "https://uploads-ssl.webflow.com/65e730dac2c13b1de20db419/65e9c61e3d65cd2c13eeb22e_image-9.jpeg",
}) {
  return (
    <_Component
      className="article-grid-image"
      id="w-node-_315b2812-11aa-ce1b-43d9-23fa19723c04-19723c04"
    >
      <_Builtin.Cell id="w-node-_315b2812-11aa-ce1b-43d9-23fa19723c05-19723c04">
        <_Builtin.Image
          className="image-bottom-gird"
          loading="lazy"
          width="auto"
          height="auto"
          alt=""
          src={firstImage}
        />
      </_Builtin.Cell>
      <_Builtin.Cell id="w-node-_315b2812-11aa-ce1b-43d9-23fa19723c07-19723c04">
        <_Builtin.Image
          className="image-bottom-gird"
          loading="lazy"
          width="auto"
          height="auto"
          alt=""
          src={secondImage}
        />
      </_Builtin.Cell>
      <_Builtin.Cell id="w-node-_315b2812-11aa-ce1b-43d9-23fa19723c09-19723c04">
        <_Builtin.Image
          className="image-bottom-gird"
          loading="lazy"
          width="auto"
          height="auto"
          alt=""
          src={thirdImage}
        />
      </_Builtin.Cell>
    </_Component>
  );
}
