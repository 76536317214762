import { PageNumber } from "devlink";
import { articleModel } from "entities/article";
import { StoreApi } from "zustand";

type BlogPaginationProps = {
  filterStore: StoreApi<articleModel.FilterState>;
};

export function BlogPagination({ filterStore }: BlogPaginationProps) {
  function scrollToTop() {
    const element = document.querySelectorAll(
      ".section-articles,.section-display-search",
    );
    element[0]?.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <>
      {Array.from({
        length: filterStore.getState().pageQuery.pageCount || 0,
      }).map((_, index) => (
        <PageNumber
          key={index}
          text={index + 1}
          rpPageNumber={{
            className: `text-P1-med pagination-link ${filterStore.getState().pageQuery.page === index + 1 ? "is-active" : ""}`,
            onClick: () => {
              filterStore.setState({
                pageQuery: {
                  ...filterStore.getState().pageQuery,
                  page: index + 1,
                },
              });
              scrollToTop();
            },
          }}
        />
      ))}
    </>
  );
}
