import { CategorieArticle as CategoryArticleDevlink } from "devlink";
import { categoryTypes } from "entities/category";
import { IconComponent } from "shared/ui/icon";

type CategoryArticleProps = {
  category: categoryTypes.Category;
};
export function CategoryArticle({ category }: CategoryArticleProps) {
  return (
    <CategoryArticleDevlink
      name={category.nom}
      slotPictogramme={<IconComponent name={category.pictogramme} />}
    />
  );
}
