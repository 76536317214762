import { Image, ImageDto } from "./image.types";

export function mapImage(imageDto: ImageDto): Image | undefined {
  if (!imageDto.data) return undefined;

  return {
    id: imageDto.data.id,
    ...imageDto.data.attributes,
  };
}
