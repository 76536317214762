import { tagContracts } from "entities/tag";
import { ImageDtoSchema, ImageSchema } from "entities/image/image.contracts";
import { articleCorpsContracts } from "entities/article-corps";
import { z } from "zod";
import { categoryContracts } from "entities/category";
import { authorContracts } from "entities/author";
import { leadMagnetContracts } from "entities/lead-magnet";
import { articleSeoContracts } from "entities/article-seo";

/**
 * Query contracts
 */

export const PageQueryDtoSchema = z.object({
  page: z.number().min(1),
  pageSize: z.number().min(1),
  pageCount: z.number().optional(), // TODO : try removing optional
  total: z.number().optional(), // TODO : try removing optional
});

export const FilterQueryDtoSchema = z.object({
  topArticle: z.boolean().optional(),
  categories: z.array(z.number()).min(1).optional(),
  tags: z.array(z.number()).min(1).optional(),
  populate: z.string().optional(),
});

export const ArticlesQueryDtoSchema = z.intersection(
  PageQueryDtoSchema,
  FilterQueryDtoSchema,
);

/**
 * Article DTO contracts
 */

const ArticleDto = z.object({
  id: z.number(),
  attributes: z.object({
    titre: z.string(),
    chapeau: z.string(),
    chapeauApercu: z.string().nullable(),
    slug: z.string(),
    topArticle: z.boolean(),
    image: ImageDtoSchema.optional(),
    vignette: ImageDtoSchema.optional(),
    auteur: authorContracts.AuthorDtoSchema.optional().nullable(),
    tags: tagContracts.TagsDtoSchema.optional().nullable(),
    categoriesArticle: categoryContracts.CategoriesDtoSchema.optional(),
    leadMagnet: leadMagnetContracts.LeadMagnetDtoSchema.optional(),
    corps: z
      .array(
        z.union([
          articleCorpsContracts.RichTextDtoSchema,
          articleCorpsContracts.PhotoFullDtoSchema,
          articleCorpsContracts.TextLeftPhotoDtoSchema,
          articleCorpsContracts.TextRightPhotoDtoSchema,
          articleCorpsContracts.PhotoOneThirdTwoThirdDtoSchema,
          articleCorpsContracts.ImageHalfHalfDtoSchema,
          articleCorpsContracts.ThirdImagesDtoSchema,
          articleCorpsContracts.FourImagesDtoSchema,
          articleCorpsContracts.CitationDtoSchema,
          articleCorpsContracts.ArticleCardCtaDtoSchema,
        ]),
      )
      .optional(),
    seo: articleSeoContracts.SeoDtoSchema.optional().nullable(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
    publishedAt: z.string().datetime(),
  }),
});

export const ArticleDtoSchema = z.object({
  data: ArticleDto.merge(
    z.object({
      attributes: ArticleDto.shape.attributes.extend({
        recommandations: z.object({ data: z.array(ArticleDto).optional() }),
      }),
    }),
  ),
});

export const ArticlesDtoSchema = z.object({
  data: z.array(ArticleDto),
  meta: z.object({
    pagination: z.object({
      page: z.number(),
      pageSize: z.number(),
      pageCount: z.number(),
      total: z.number(),
    }),
  }),
});

/**
 * Article contracts
 */

export const ArticleSchema = z.object({
  id: z.number(),
  titre: z.string(),
  chapeau: z.string(),
  chapeauApercu: z.string().nullable(),
  slug: z.string(),
  topArticle: z.boolean(),
  image: ImageSchema.optional(),
  vignette: ImageSchema.optional(),
  auteur: authorContracts.AuthorSchema.optional(),
  tags: tagContracts.TagsSchema.optional(),
  categoriesArticle: categoryContracts.CategoriesSchema.optional(),
  leadMagnet: leadMagnetContracts.LeadMagnetSchema.optional(),
  corps: z
    .array(
      z.union([
        articleCorpsContracts.RichTextDtoSchema,
        articleCorpsContracts.PhotoFullDtoSchema,
        articleCorpsContracts.TextLeftPhotoDtoSchema,
        articleCorpsContracts.TextRightPhotoDtoSchema,
        articleCorpsContracts.PhotoOneThirdTwoThirdDtoSchema,
        articleCorpsContracts.ImageHalfHalfDtoSchema,
        articleCorpsContracts.ThirdImagesDtoSchema,
        articleCorpsContracts.FourImagesDtoSchema,
        articleCorpsContracts.CitationDtoSchema,
        articleCorpsContracts.ArticleCardCtaDtoSchema,
      ]),
    )
    .optional(),
  seo: articleSeoContracts.SeoSchema.optional().nullable(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  publishedAt: z.string().datetime(),
});

export const ArticlesSchema = z.object({
  data: z.array(ArticleSchema),
  meta: PageQueryDtoSchema,
});

export const ArticleWithRecommandationsSchema = ArticleSchema.extend({
  recommandations: z.array(ArticleSchema).optional(),
});
