import { ImageDtoSchema } from "entities/image/image.contracts";
import { z } from "zod";

export const RichTextDtoSchema = z.object({
  id: z.number(),
  __component: z.string(),
  contenu: z.string(),
});

export const PhotoFullDtoSchema = z.object({
  id: z.number(),
  __component: z.string(),
  image: ImageDtoSchema,
});

export const TextRightPhotoDtoSchema = z.object({
  id: z.number(),
  __component: z.string(),
  contenu: z.string(),
  image: ImageDtoSchema,
});

export const TextLeftPhotoDtoSchema = z.object({
  id: z.number(),
  __component: z.string(),
  image: ImageDtoSchema,
  contenu: z.string(),
});

export const PhotoOneThirdTwoThirdDtoSchema = z.object({
  id: z.number(),
  __component: z.string(),
  imageUnTier: ImageDtoSchema,
  imageDeuxTiers: ImageDtoSchema,
});

export const ImageHalfHalfDtoSchema = z.object({
  id: z.number(),
  __component: z.string(),
  imageGauche: ImageDtoSchema,
  imageDroite: ImageDtoSchema,
});

export const ThirdImagesDtoSchema = z.object({
  id: z.number(),
  __component: z.string(),
  image1: ImageDtoSchema,
  image2: ImageDtoSchema,
  image3: ImageDtoSchema,
});

export const FourImagesDtoSchema = z.object({
  id: z.number(),
  __component: z.string(),
  image1: ImageDtoSchema,
  image2: ImageDtoSchema,
  image3: ImageDtoSchema,
  image4: ImageDtoSchema,
});

export const CitationDtoSchema = z.object({
  id: z.number(),
  __component: z.string(),
  contenu: z.string(),
  auteur: z.object({
    data: z.object({
      attributes: z.object({
        nom: z.string(),
        poste: z.string(),
        createdAt: z.string(),
        updatedAt: z.string(),
        publishedAt: z.string(),
      }),
      id: z.number(),
    }),
  }),
});

export const ArticleCardCtaDtoSchema = z.object({
  id: z.number(),
  __component: z.string(),
  title: z.string(),
  content: z.string(),
  titleButton: z.string(),
  lien: z.string(),
});
