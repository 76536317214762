import { useQuery } from "@tanstack/react-query";
import { articleQueries } from "entities/article";
import { useParams } from "react-router";
import { pathKeys, routerTypes } from "shared/lib/react-router";
import { SectionArticleContent } from "widgets/section-article-content";
import { SectionArticleHero } from "widgets/section-article-hero";
import { SectionArticleRecommended } from "widgets/section-article-recommandation";
import { ArticleSeo } from "widgets/article-seo";
import { articleFilterStore } from "pages/home/home-page.model";

export function ArticlePage() {
  const { slug } = useParams() as routerTypes.SlugPageParams;
  const {
    data: article,
    isLoading,
    error,
  } = useQuery(articleQueries.articleService.queryOptions(slug));

  if (error && (error as any).status === 404) {
    window.location.href = pathKeys.page404();
  }

  if (isLoading || !article) return <div>Loading...</div>;

  if (error) {
    console.error(error);
  }

  return (
    <>
      <ArticleSeo article={article} />
      <SectionArticleHero article={article} filterStore={articleFilterStore} />
      <SectionArticleContent article={article} />
      <SectionArticleRecommended article={article} />
    </>
  );
}
