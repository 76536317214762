import { articleSeoTypes } from "entities/article-seo";

export const renderTwitterMetaTags = (
  seo: articleSeoTypes.Seo,
  currentSocial: articleSeoTypes.SeoMetaSocial,
): JSX.Element[] => [
  <meta key="twitter:card" name="twitter:card" content="summary_large_image" />,
  <meta
    key="twitter:title"
    name="twitter:title"
    content={currentSocial.title || undefined}
  />,
  <meta
    key="twitter:description"
    name="twitter:description"
    content={currentSocial.description || undefined}
  />,
  <meta
    key="twitter:image"
    name="twitter:image"
    content={seo.metaImage?.url}
  />,
  <meta
    key="twitter:site"
    name="twitter:site"
    content="lentillesmoinscheres.com"
  />,
];
