import { SectionHero as SectionHeroDevlink } from "devlink";
import { useQuery } from "@tanstack/react-query";
import { tagQueries } from "entities/tag";
import { TagArticle } from "widgets/tag-article";
import { StoreApi } from "zustand";
import { articleModel } from "entities/article";
import "./section-hero.css";

type SectionTopArticlesProps = {
  filterStore: StoreApi<articleModel.FilterState>;
};
export function SectionHero({ filterStore }: SectionTopArticlesProps) {
  const { data: tags } = useQuery(
    tagQueries.tagsService.queryOptions({ limit: 9999 }),
  );
  return (
    <SectionHeroDevlink
      customImg="/images/baseHeroImg.jpeg"
      slotBlogHeroTopTags={
        tags ? (
          tags
            .filter((tag) => tag.misEnAvant)
            .map((tag) => (
              <TagArticle filterStore={filterStore} key={tag.id} tag={tag} />
            ))
        ) : (
          <></>
        )
      }
    />
  );
}
