import { ImageDtoSchema, ImageSchema } from "entities/image/image.contracts";
import { z } from "zod";

export const AuthorDtoSchema = z.object({
  data: z
    .object({
      id: z.number(),
      attributes: z.object({
        nom: z.string(),
        poste: z.string(),
        image: ImageDtoSchema.optional(),
        createdAt: z.string().datetime(),
        updatedAt: z.string().datetime(),
      }),
    })
    .nullable(),
});

export const AuthorSchema = z.object({
  id: z.number(),
  nom: z.string(),
  poste: z.string(),
  image: ImageSchema.optional(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
});
