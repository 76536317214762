import { ImageDtoSchema, ImageSchema } from "entities/image/image.contracts";
import { z } from "zod";

export const LeadMagnetDtoSchema = z.object({
  data: z
    .object({
      id: z.number(),
      attributes: z.object({
        titre: z.string(),
        lien: z.string(),
        texteBouton: z.string(),
        image: ImageDtoSchema.optional(),
        bulletPoints: z.array(
          z.object({
            texte: z.string(),
            pictogramme: z.string(),
          }),
        ),
        createdAt: z.string().datetime(),
        updatedAt: z.string().datetime(),
      }),
    })
    .nullable(),
});

export const LeadMagnetSchema = z.object({
  id: z.number(),
  titre: z.string(),
  lien: z.string(),
  texteBouton: z.string(),
  image: ImageSchema.optional(),
  bulletPoints: z.array(
    z.object({
      texte: z.string(),
      pictogramme: z.string(),
    }),
  ),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
});
