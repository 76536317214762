import { z } from "zod";

export const ImageFormatDtoSchema = z.object({
  ext: z.string(),
  url: z.string(),
  hash: z.string(),
  mime: z.string(),
  name: z.string(),
  path: z.string().nullable(),
  size: z.number(),
  width: z.number(),
  height: z.number(),
});

export const ImageDtoSchema = z.object({
  data: z
    .object({
      id: z.number(),
      attributes: z.object({
        name: z.string(),
        alternativeText: z.string().nullable(),
        caption: z.string().nullable(),
        width: z.number(),
        height: z.number(),
        formats: z
          .object({
            thumbnail: ImageFormatDtoSchema.optional(),
            large: ImageFormatDtoSchema.optional(),
            medium: ImageFormatDtoSchema.optional(),
            small: ImageFormatDtoSchema.optional(),
          })
          .nullable(),
        hash: z.string(),
        ext: z.string(),
        mime: z.string(),
        size: z.number(),
        url: z.string(),
        previewUrl: z.string().nullable(),
        provider: z.string(),
        provider_metadata: z.string().nullable(),
        createdAt: z.string().datetime(),
        updatedAt: z.string().datetime(),
      }),
    })
    .nullable(),
});

export const ImageSchema = z.object({
  id: z.number(),
  name: z.string(),
  alternativeText: z.string().nullable(),
  caption: z.string().nullable(),
  width: z.number(),
  height: z.number(),
  formats: z
    .object({
      thumbnail: ImageFormatDtoSchema.optional(),
      large: ImageFormatDtoSchema.optional(),
      medium: ImageFormatDtoSchema.optional(),
      small: ImageFormatDtoSchema.optional(),
    })
    .nullable(),
  hash: z.string(),
  ext: z.string(),
  mime: z.string(),
  size: z.number(),
  url: z.string(),
  previewUrl: z.string().nullable(),
  provider: z.string(),
  provider_metadata: z.string().nullable(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
});
