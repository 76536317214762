import "./section-article-content.css";
import {
  SectionArticleContent as SectionArticleContentDevlink,
  ArticleTopInfos,
  ArticleCta,
  RowCtaSmall,
} from "devlink";
import { articleTypes } from "entities/article";
import { imageUrl } from "shared/api/sensee";
import { articleCorpsTypes } from "entities/article-corps";
import { ArticleAuthor } from "widgets/article-author";
import { IconComponent } from "shared/ui/icon";
import {
  Citation,
  ImageOneThirdTwoThird,
  PhotoFull,
  ImageHalfHalf,
  TroisImages,
  QuatreImages,
  RichText,
  TextLeftPhoto,
  TextRightPhoto,
  ImageTwoThirdOneThird,
  CardCta,
} from "widgets/article-corps";

type ArticleContentProps = {
  article: articleTypes.Article;
};
export function SectionArticleContent({ article }: ArticleContentProps) {
  return (
    <SectionArticleContentDevlink
      slotArticleCta={
        <ArticleCta
          slotTitleLeadMagnet={
            <h3>
              {article.leadMagnet?.titre.split(" ").map((word, index) => (
                <span
                  key={index}
                  className={`highlight-text ${index === 0 && "start"} ${index === article.leadMagnet!.titre.split(" ").length - 1 && "end"}`}
                >
                  {`${word}${index !== article.leadMagnet!.titre.split(" ").length - 1 ? " " : ""}`}
                </span>
              ))}
            </h3>
          }
          title={article.leadMagnet?.titre}
          content={article.leadMagnet?.texteBouton}
          slotRowAvantage={article.leadMagnet?.bulletPoints.map(
            (bulletPoint, index) => (
              <RowCtaSmall
                key={index}
                content={bulletPoint.texte}
                slotIcon={
                  <IconComponent
                    name={bulletPoint.pictogramme}
                    size={20}
                    color="#9EBFFF"
                  />
                }
              />
            ),
          )}
          link={{ href: `${article.leadMagnet?.lien}`, target: "_blank" }}
          rpBackgroundImage={{
            style: {
              backgroundImage: `linear-gradient(95deg, hsla(0, 0.00%, 0.00%, 0.70), hsla(0, 0.00%, 100.00%, 0.00)), url(${article.leadMagnet?.image?.url ? imageUrl(article.leadMagnet?.image?.url) : "/images/default-image.jpg"})`,
            },
          }}
        />
      }
      slotHeaderArticle={
        <ArticleTopInfos
          chapeau={article.chapeau}
          date={`Publié le ${new Date(article.createdAt).toLocaleDateString("fr-FR")}`}
          rpShareButton={{
            onClick: (event: any) => {
              event.preventDefault();
              const textShareButton =
                event.currentTarget.querySelector(".text-share-button");
              if (textShareButton) {
                const svg = event.currentTarget.querySelector(".flex-center");
                svg.style.display = "none";
                textShareButton.textContent = "Le lien a bien été copié !";
                setTimeout(() => {
                  textShareButton.textContent = "Partager le lien de l'article";
                  svg.style.display = "flex";
                }, 2000);
              }
              navigator.clipboard.writeText(window.location.href);
            },
          }}
        />
      }
      slotContentArticle={
        <div className="corps">
          {article.corps?.map((corps, index) => {
            if (corps.__component === "corps-article.rich-text") {
              return (
                <RichText
                  key={index}
                  corps={corps as articleCorpsTypes.RichTextDto}
                />
              );
            }
            if (corps.__component === "corps-article.photo-full") {
              return (
                <PhotoFull
                  key={index}
                  corps={corps as articleCorpsTypes.PhotoFullDto}
                />
              );
            }
            if (corps.__component === "corps-article.text-photo-droite") {
              return (
                <TextRightPhoto
                  key={index}
                  corps={corps as articleCorpsTypes.TextRightPhotoDto}
                />
              );
            }

            if (corps.__component === "corps-article.text-photo-gauche") {
              return (
                <TextLeftPhoto
                  key={index}
                  corps={corps as articleCorpsTypes.TextLeftPhotoDto}
                />
              );
            }

            if (
              corps.__component === "corps-article.photos1-3-2-3" &&
              "imageUnTier" in corps &&
              "imageDeuxTiers" in corps
            ) {
              return (
                <ImageOneThirdTwoThird
                  key={index}
                  corps={corps as articleCorpsTypes.PhotoOneThirdTwoThirdDto}
                />
              );
            }

            if (
              corps.__component === "corps-article.photos2-3-1-3" &&
              "imageUnTier" in corps &&
              "imageDeuxTiers" in corps
            ) {
              return (
                <ImageTwoThirdOneThird
                  key={index}
                  corps={corps as articleCorpsTypes.PhotoOneThirdTwoThirdDto}
                />
              );
            }

            if (
              corps.__component === "corps-article.photos50-50" &&
              "imageGauche" in corps &&
              "imageDroite" in corps
            ) {
              return (
                <ImageHalfHalf
                  key={index}
                  corps={corps as articleCorpsTypes.ImageHalfHalfDto}
                />
              );
            }

            if (
              corps.__component === "corps-article.trois-photos" &&
              "image1" in corps &&
              "image2" in corps &&
              "image3" in corps
            ) {
              return (
                <TroisImages
                  key={index}
                  corps={corps as articleCorpsTypes.ThirdImagesDto}
                />
              );
            }

            if (
              corps.__component === "corps-article.quatre-photos" &&
              "image1" in corps &&
              "image2" in corps &&
              "image3" in corps &&
              "image4" in corps
            ) {
              return (
                <QuatreImages
                  key={index}
                  corps={corps as articleCorpsTypes.FourImagesDto}
                />
              );
            }

            if (corps.__component === "corps-article.citation") {
              return (
                <Citation
                  key={index}
                  corps={corps as articleCorpsTypes.CitationDto}
                />
              );
            }

            if (corps.__component === "corps-article.cta") {
              return (
                <CardCta
                  key={index}
                  corps={corps as articleCorpsTypes.ArticleCardCtaDto}
                />
              );
            }
            return null;
          })}
          {article.auteur && <ArticleAuthor author={article.auteur} />}
        </div>
      }
    />
  );
}
