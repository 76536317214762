import { useQuery } from "@tanstack/react-query";
import { SectionTopArticles as SectionTopArticlesDevlink } from "devlink";
import { articleModel, articleQueries } from "entities/article";
import { CardArticle } from "widgets/card-article";
import { StoreApi } from "zustand";
import { useLayoutEffect, useState } from "react";

type SectionTopArticlesProps = {
  filterStore: StoreApi<articleModel.FilterState>;
};
export function SectionTopArticles({ filterStore }: SectionTopArticlesProps) {
  const { data: topArticles } = useQuery(
    articleQueries.paginatedArticlesService.queryOptions(
      { page: 1, pageSize: 3 },
      { topArticle: true, populate: "image,vignette,tags,categoriesArticle" },
    ),
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filterQuery, setFilterQuery] = useState(
    filterStore.getState().filterQuery,
  );

  const hasTags =
    !filterStore.getState().filterQuery.tags ||
    filterStore.getState().filterQuery.tags?.length === 0;

  useLayoutEffect(() => {
    filterStore.subscribe((state: articleModel.State) => {
      setFilterQuery(state.filterQuery);
    });
  }, [filterStore]);

  if (!topArticles) return <></>;

  return (
    <>
      {hasTags && topArticles.data.length > 0 ? (
        <SectionTopArticlesDevlink
          slotLeftArticle={
            <CardArticle
              article={topArticles.data[0]}
              filterStore={filterStore}
            />
          }
          slotRightArticles={
            <>
              {topArticles.data.length > 1 && (
                <CardArticle
                  className="top-small"
                  article={topArticles.data[1]}
                  filterStore={filterStore}
                />
              )}
              {topArticles.data.length > 2 && (
                <CardArticle
                  className="top-small"
                  article={topArticles.data[2]}
                  filterStore={filterStore}
                />
              )}
            </>
          }
        />
      ) : (
        <></>
      )}
    </>
  );
}
