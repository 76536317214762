import { z } from "zod";

export const CategoryDtoSchema = z.object({
  data: z.object({
    id: z.number(),
    attributes: z.object({
      nom: z.string(),
      pictogramme: z.string(),
      createdAt: z.string(),
      updatedAt: z.string(),
      publishedAt: z.string(),
    }),
  }),
});

export const CategoriesDtoSchema = z.object({
  data: z.array(
    z.object({
      id: z.number(),
      attributes: z.object({
        nom: z.string(),
        pictogramme: z.string(),
        createdAt: z.string(),
        updatedAt: z.string(),
        publishedAt: z.string(),
      }),
    }),
  ),
});

export const CategorySchema = z.object({
  id: z.number(),
  nom: z.string(),
  pictogramme: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  publishedAt: z.string(),
});

export const CategoriesSchema = z.array(CategorySchema);
