import { z } from "zod";

export const TagDtoSchema = z.object({
  data: z.object({
    id: z.number(),
    attributes: z.object({
      nom: z.string(),
      misEnAvant: z.boolean(),
      createdAt: z.string(),
      updatedAt: z.string(),
      publishedAt: z.string(),
    }),
  }),
});

export const TagsDtoSchema = z.object({
  data: z.array(
    z.object({
      id: z.number(),
      attributes: z.object({
        nom: z.string(),
        misEnAvant: z.boolean(),
        createdAt: z.string(),
        updatedAt: z.string(),
        publishedAt: z.string(),
      }),
    }),
  ),
});

export const FilterQuerySchema = z.object({
  misEnAvant: z.boolean().optional(),
  limit: z.number().optional(),
});

export const TagSchema = z.object({
  id: z.number(),
  nom: z.string(),
  misEnAvant: z.boolean(),
  createdAt: z.string(),
  updatedAt: z.string(),
  publishedAt: z.string(),
});

export const TagsSchema = z.array(TagSchema);
