"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import { ArticleCta } from "./ArticleCta";
import { ArticleTopInfos } from "./ArticleTopInfos";
import { ArticleRichText } from "./ArticleRichText";
import { ArticleCardCta } from "./ArticleCardCta";
import { ArticleDeuxTiersImage } from "./ArticleDeuxTiersImage";
import { ArticleTroisImages } from "./ArticleTroisImages";
import { ArticleCitation } from "./ArticleCitation";
import { ArticleImageFull } from "./ArticleImageFull";
import { ArticleQuatreImages } from "./ArticleQuatreImages";
import { ArticleAuthor } from "./ArticleAuthor";

export function SectionArticleContent({
  as: _Component = _Builtin.Block,
  slotContentArticle,
  slotHeaderArticle,
  slotArticleCta,
}) {
  return (
    <_Component
      className="section-content-article padding-vertical padding-huge"
      tag="div"
    >
      <_Builtin.Block className="padding-global" tag="div">
        <_Builtin.Block className="container-large" tag="div">
          <_Builtin.Block className="content-page-article" tag="div">
            <_Builtin.Block className="container-article-cta" tag="div">
              {slotArticleCta ?? <ArticleCta />}
            </_Builtin.Block>
            <_Builtin.Block className="wrapper-one-article" tag="aside">
              <_Builtin.Block className="wrapper-top-infos-article" tag="div">
                {slotHeaderArticle ?? <ArticleTopInfos />}
              </_Builtin.Block>
              <_Builtin.Block className="wrapper-content-article" tag="div">
                {slotContentArticle ?? (
                  <>
                    <_Builtin.Block className="text-content-article" tag="div">
                      <_Builtin.Heading tag="h2">
                        {"Lorem ipsum dolor sit"}
                      </_Builtin.Heading>
                      <_Builtin.Paragraph className="text-p1-med">
                        {
                          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae risus tristique posuere."
                        }
                      </_Builtin.Paragraph>
                    </_Builtin.Block>
                    <ArticleRichText />
                    <ArticleCardCta />
                    <_Builtin.Block className="text-content-article" tag="div">
                      <_Builtin.Heading tag="h3">
                        {"Lorem ipsum dolor sit"}
                      </_Builtin.Heading>
                      <_Builtin.Paragraph className="text-p1-med">
                        {
                          "Lorem ipsum dolor sit amet consectetur. Purus risus nulla tempor fermentum amet. Odio nunc pretium eget at blandit. Cras facilisis sapien dui felis turpis turpis fringilla eu pellentesque. Posuere morbi adipiscing odio purus aliquet hendrerit tincidunt turpis. Lorem ipsum dolor sit amet consectetur. Purus risus nulla tempor fermentum amet. Odio nunc pretium eget at blandit. Cras facilisis sapien dui felis turpis turpis fringilla eu pellentesque. Posuere morbi adipiscing odio purus aliquet hendrerit tincidunt turpis."
                        }
                      </_Builtin.Paragraph>
                      <_Builtin.Paragraph className="text-p1-med">
                        {
                          "Lorem ipsum dolor sit amet consectetur. Purus risus nulla tempor fermentum amet. Odio nunc pretium eget at blandit. Cras facilisis sapien dui felis turpis turpis fringilla eu pellentesque. Posuere morbi adipiscing odio purus aliquet hendrerit tincidunt turpis. Lorem ipsum dolor sit amet consectetur. Purus risus nulla tempor fermentum amet. Odio nunc pretium eget at blandit. Cras facilisis sapien dui felis turpis turpis fringilla eu pellentesque. Posuere morbi adipiscing odio purus aliquet hendrerit tincidunt turpis."
                        }
                      </_Builtin.Paragraph>
                    </_Builtin.Block>
                    <_Builtin.Block
                      className="container-grid-article-image"
                      tag="div"
                    >
                      <ArticleDeuxTiersImage />
                      <ArticleTroisImages />
                      <ArticleCardCta />
                    </_Builtin.Block>
                    <_Builtin.Block className="text-content-article" tag="div">
                      <_Builtin.Heading tag="h3">
                        {"Lorem ipsum dolor sit"}
                      </_Builtin.Heading>
                      <_Builtin.Paragraph className="text-p1-med">
                        {
                          "Lorem ipsum dolor sit amet consectetur. Purus risus nulla tempor fermentum amet. Odio nunc pretium eget at blandit. Cras facilisis sapien dui felis turpis turpis fringilla eu pellentesque. Posuere morbi adipiscing odio purus aliquet hendrerit tincidunt turpis. Lorem ipsum dolor sit amet consectetur. Purus risus nulla tempor fermentum amet. Odio nunc pretium eget at blandit. Cras facilisis sapien dui felis turpis turpis fringilla eu pellentesque. Posuere morbi adipiscing odio purus aliquet hendrerit tincidunt turpis."
                        }
                      </_Builtin.Paragraph>
                    </_Builtin.Block>
                    <ArticleCitation />
                    <ArticleImageFull />
                    <_Builtin.Block className="text-list-article" tag="div">
                      <_Builtin.Block
                        className="text-content-article"
                        tag="div"
                      >
                        <_Builtin.Heading tag="h3">
                          {"Lorem ipsum dolor sit"}
                        </_Builtin.Heading>
                        <_Builtin.Paragraph className="text-p1-med">
                          {
                            "Lorem ipsum dolor sit amet consectetur. Purus risus nulla tempor fermentum amet. Odio nunc pretium eget at blandit. Cras facilisis sapien dui felis turpis turpis fringilla eu pellentesque. Posuere morbi adipiscing odio purus aliquet hendrerit tincidunt turpis. Ut quis vel feugiat fermentum neque pulvinar porttitor risus eget. Volutpat dolor euismod elementum viverra tortor augue massa varius. Sed enim risus mi habitasse. Eu consectetur erat proin morbi. Diam eu in dictum sapien proin ut."
                          }
                        </_Builtin.Paragraph>
                      </_Builtin.Block>
                      <_Builtin.Block
                        className="text-content-article"
                        tag="div"
                      >
                        <_Builtin.Heading tag="h4">
                          {"1. Lorem ipsum dolor sit"}
                        </_Builtin.Heading>
                        <_Builtin.Paragraph className="text-p1-med">
                          {
                            "Lorem ipsum dolor sit amet consectetur. Purus risus nulla tempor fermentum amet. Odio nunc pretium eget at blandit. Cras facilisis sapien dui felis turpis turpis fringilla eu pellentesque. Posuere morbi adipiscing odio purus aliquet hendrerit tincidunt turpis."
                          }
                        </_Builtin.Paragraph>
                      </_Builtin.Block>
                      <_Builtin.Block
                        className="text-content-article"
                        tag="div"
                      >
                        <_Builtin.Heading tag="h4">
                          {"2. Lorem ipsum dolor sit"}
                        </_Builtin.Heading>
                        <_Builtin.Paragraph className="text-p1-med">
                          {
                            "Lorem ipsum dolor sit amet consectetur. Purus risus nulla tempor fermentum amet. Odio nunc pretium eget at blandit. Cras facilisis sapien dui felis turpis turpis fringilla eu pellentesque. Posuere morbi adipiscing odio purus aliquet hendrerit tincidunt turpis."
                          }
                        </_Builtin.Paragraph>
                      </_Builtin.Block>
                      <_Builtin.Block
                        className="text-content-article"
                        tag="div"
                      >
                        <_Builtin.Heading tag="h4">
                          {"3. Lorem ipsum dolor sit"}
                        </_Builtin.Heading>
                        <_Builtin.Paragraph className="text-p1-med">
                          {
                            "Lorem ipsum dolor sit amet consectetur. Purus risus nulla tempor fermentum amet. Odio nunc pretium eget at blandit. Cras facilisis sapien dui felis turpis turpis fringilla eu pellentesque. Posuere morbi adipiscing odio purus aliquet hendrerit tincidunt turpis."
                          }
                        </_Builtin.Paragraph>
                      </_Builtin.Block>
                    </_Builtin.Block>
                    <ArticleQuatreImages />
                    <_Builtin.Block className="divider-article" tag="div" />
                    <_Builtin.Block className="article-footer" tag="div">
                      <ArticleAuthor />
                    </_Builtin.Block>
                  </>
                )}
              </_Builtin.Block>
            </_Builtin.Block>
          </_Builtin.Block>
        </_Builtin.Block>
      </_Builtin.Block>
    </_Component>
  );
}
