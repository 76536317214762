import { FetchApiRecord } from "shared/lib/fetch";
import {
  Article,
  ArticleDto,
  Articles,
  ArticlesDto,
  ArticlesQueryDto,
} from "./article.types";
import { imageLib } from "entities/image";
import { tagLib } from "entities/tag";
import { categoryLib } from "entities/category";
import { authorLib } from "entities/author";
import { leadMagnetLib } from "entities/lead-magnet";
import { articleSeoLib } from "entities/article-seo";

export function mapArticle(articleDto: ArticleDto): Article {
  return {
    id: articleDto.data.id,
    ...articleDto.data.attributes,
    image: articleDto.data.attributes.image
      ? imageLib.mapImage(articleDto.data.attributes.image)
      : undefined,
    vignette: articleDto.data.attributes.vignette
      ? imageLib.mapImage(articleDto.data.attributes.vignette)
      : undefined,
    auteur: articleDto.data.attributes.auteur
      ? authorLib.mapAuthor(articleDto.data.attributes.auteur)
      : undefined,
    tags: articleDto.data.attributes.tags
      ? tagLib.mapTags(articleDto.data.attributes.tags)
      : undefined,
    categoriesArticle: articleDto.data.attributes.categoriesArticle
      ? categoryLib.mapCategories(articleDto.data.attributes.categoriesArticle)
      : undefined,
    seo: articleDto.data.attributes.seo
      ? articleSeoLib.mapSeo(articleDto.data.attributes.seo)
      : undefined,
    leadMagnet: articleDto.data.attributes.leadMagnet
      ? leadMagnetLib.mapLeadMagnet(articleDto.data.attributes.leadMagnet)
      : undefined,
    recommandations: articleDto.data.attributes.recommandations?.data
      ? (articleDto.data.attributes.recommandations.data as any).map(
          (article: any) => mapArticle({ data: article } as ArticleDto),
        )
      : undefined, // TODO : fix recommandations
  };
}

export function mapArticles(articleDtos: ArticlesDto): Articles {
  const data = articleDtos.data.map((articleDto) => ({
    id: articleDto.id,
    ...articleDto.attributes,
    image: articleDto.attributes.image
      ? imageLib.mapImage(articleDto.attributes.image)
      : undefined,
    vignette: articleDto.attributes.vignette
      ? imageLib.mapImage(articleDto.attributes.vignette)
      : undefined,
    auteur: articleDto.attributes.auteur
      ? authorLib.mapAuthor(articleDto.attributes.auteur)
      : undefined,
    tags: articleDto.attributes.tags
      ? tagLib.mapTags(articleDto.attributes.tags)
      : undefined,
    categoriesArticle: articleDto.attributes.categoriesArticle
      ? categoryLib.mapCategories(articleDto.attributes.categoriesArticle)
      : undefined,
    seo: articleDto.attributes.seo
      ? articleSeoLib.mapSeo(articleDto.attributes.seo)
      : undefined,
    leadMagnet: articleDto.attributes.leadMagnet
      ? leadMagnetLib.mapLeadMagnet(articleDto.attributes.leadMagnet)
      : undefined,
  }));
  const meta = articleDtos.meta.pagination;
  return { data, meta };
}

export function mapArticleQuery(
  articleQueryDto: ArticlesQueryDto,
): FetchApiRecord {
  const query = new URLSearchParams();
  // default sort by createdAt desc
  query.set("sort", "createdAt:desc");

  // pagination
  query.set("pagination[page]", articleQueryDto.page.toString());
  query.set("pagination[pageSize]", articleQueryDto.pageSize.toString());

  // populate
  query.set("populate", articleQueryDto.populate ?? "");

  // filters
  if (
    articleQueryDto.topArticle !== undefined &&
    articleQueryDto.tags === undefined
  ) {
    query.set("filters[topArticle]", articleQueryDto.topArticle.toString());
  }
  if (
    articleQueryDto.categories !== undefined &&
    articleQueryDto.categories.length > 0
  ) {
    for (let i = 0; i < articleQueryDto.categories.length; i++) {
      query.append(
        `filters[categoriesArticle][$or][${i}][id][$eq]`,
        articleQueryDto.categories[i].toString(),
      );
    }
  }
  if (articleQueryDto.tags !== undefined && articleQueryDto.tags.length > 0) {
    for (let i = 0; i < articleQueryDto.tags.length; i++) {
      query.append(
        `filters[tags][$or][${i}][id][$eq]`,
        articleQueryDto.tags[i].toString(),
      );
    }
  }
  return Object.fromEntries(query) as FetchApiRecord;
}
