import { FetchApiRecord } from "shared/lib/fetch";
import { Tag, TagDto, Tags, TagsDto, TagsFilterQuery } from "./tag.types";

export function mapTag(tagDto: TagDto): Tag {
  return { id: tagDto.data.id, ...tagDto.data.attributes };
}

export function mapTags(tagDtos: TagsDto): Tags {
  return tagDtos.data.map((tagDto) => ({
    id: tagDto.id,
    ...tagDto.attributes,
  }));
}

export function mapTagsQuery(tagsQueryDto: TagsFilterQuery): FetchApiRecord {
  const query = new URLSearchParams();

  // pagination
  if (tagsQueryDto.limit !== undefined) {
    query.set("pagination[limit]", tagsQueryDto.limit.toString());
  }

  // filters
  if (tagsQueryDto.misEnAvant !== undefined) {
    query.set("filters[misEnAvant][$eq]", tagsQueryDto.misEnAvant.toString());
  }

  return Object.fromEntries(query) as FetchApiRecord;
}
